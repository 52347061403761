import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'

import $ from 'jquery';
import ripples from './plugin/Ripples';

import common from './common'
import index from './index'
import usevue from './usevue'

document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('app'), ()=>{
    usevue()
    let smoother
    smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
    common()
    index()
    setProp()

    function setProp(){
      if(smoother != null){
        document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)
        requestAnimationFrame(setProp)
      }else{
        cancelAnimationFrame(setProp)
      }
    }
    efectRipples()
  })
});

function efectRipples() {
  var $el = $('#ripples');
  $el.ripples({
    dropRadius: 16, // 波紋の大きさ
    resolution: 256, // 波紋の広がり速度
    perturbance: 0.03 // 波紋のブレ
  });

  function createRipple() {
		var x = Math.random() * $el.outerWidth();
		var y = Math.random() * $el.outerHeight();
		var dropRadius = 20;
		var strength = 0.04 + Math.random() * 0.04;

		$el.ripples('drop', x, y, dropRadius, strength);

		setTimeout(function() {
			requestAnimationFrame(createRipple);
		}, 4000);
	}

  requestAnimationFrame(createRipple);
}
